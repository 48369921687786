module.exports = {
  allLanguages: ['ca', 'de', 'en', 'es', 'fr', 'it', 'pt'],
  allLocales: ['ca-ES', 'de-DE', 'en-US', 'es-ES', 'fr-FR', 'it-IT', 'pt-PT'],
  defaultLanguage: 'en',
  loadLocaleFrom: (lang, ns) => import(`i18n/locales/${lang}/${ns}.json`).then(m => m.default),
  pages: {
    '*': ['common', 'auth', 'countries', 'userPage', 'statusNotification'],
    '/': ['home'],
    '/bookings/[bookingUUID]/wallet': ['walletLinks'],
    '/artist/[...params]': ['artistPage'],
    '/business': ['business'],
    '/email-confirmation': ['emailConfirmation'],
    '/pricing': ['pricing'],
    '/the-future/covid19': ['covid19'],
    '/user/bookings': ['bookings'],
    '/user/saved': ['saved'],
    '/user/profile': ['userPage'],
    '/user/profile/edit': ['userPage'],
    '/user/profile/settings': ['userPage'],
    '/user/profile/settings/payments': ['userPage'],
    '/user/profile/settings/personal': ['userPage'],
    '/[geo]/events': ['eventsList'],
    '/[geo]/events/[...params]': ['eventsList'],
    '/[geo]/events/all/[...params]': ['eventsList'],
    '/[geo]/bars': ['barsList'],
    '/[geo]/bars/[...params]': ['barsList'],
    '/[geo]/bars/all/[...params]': ['barsList'],
    '/[geo]/clubs': ['clubsList'],
    '/[geo]/clubs/[...params]': ['clubsList'],
    '/[geo]/clubs/all/[...params]': ['clubsList'],
    '/[geo]/restaurants': ['restaurantsList'],
    '/[geo]/restaurants/[...params]': ['restaurantsList'],
    '/[geo]/restaurants/all/[...params]': ['restaurantsList'],
    '/[geo]/event/[...params]': ['eventPage', 'preRegistration'],
    '/[geo]/bar/[...params]': ['barPage'],
    '/[geo]/club/[...params]': ['clubPage'],
    '/[geo]/restaurant/[...params]': ['restaurantPage'],
    '/[geo]': ['geoPage'],
    '/[geo]/[...params]': ['geoPage'],
    '/[geo]/artists': ['artistsList'],
    '/[geo]/artists/[...params]': ['artistsList'],
    '/[geo]/artists/all/[...params]': ['artistsList'],
    '/[geo]/preview/[...params]': ['eventPage'],
    '/tos': ['tos'],
    '/privacy-policy': ['privacy'],
  },
};
