export const ACCESS_TOKEN_KEY = process.env.NEXT_PUBLIC_ACCESS_TOKEN_KEY;
export const REFRESH_TOKEN_KEY = process.env.NEXT_PUBLIC_REFRESH_TOKEN_KEY;
export const IMPERSONATE_ACCESS_TOKEN_KEY = process.env.NEXT_PUBLIC_IMPERSONATE_ACCESS_TOKEN_KEY;
export const IMPERSONATE_REFRESH_TOKEN_KEY = process.env.NEXT_PUBLIC_IMPERSONATE_REFRESH_TOKEN_KEY;

export const STORAGE_ACCESS_TOKEN_KEY = process.env.NEXT_PUBLIC_STORAGE_ACCESS_TOKEN_KEY;
export const STORAGE_REFRESH_TOKEN_KEY = process.env.NEXT_PUBLIC_STORAGE_REFRESH_TOKEN_KEY;
export const STORAGE_IMPERSONATE_ACCESS_TOKEN_KEY =
  process.env.NEXT_PUBLIC_STORAGE_IMPERSONATE_ACCESS_TOKEN_KEY;
export const STORAGE_IMPERSONATE_REFRESH_TOKEN_KEY =
  process.env.NEXT_PUBLIC_STORAGE_IMPERSONATE_REFRESH_TOKEN_KEY;

export const SOCIAL_LOGIN_PUBLIC_KEY = process.env.NEXT_PUBLIC_SOCIAL_PUBLIC_KEY;
export const SOCIAL_LOGIN_PRIVATE_KEY = process.env.NEXT_PUBLIC_SOCIAL_PRIVATE_KEY;
export const SOCIAL_LOGIN_KEY_EXPIRATION_TIME = process.env.NEXT_PUBLIC_SOCIAL_KEY_EXPIRATION_TIME;

export const COOKIE_LOCAL_DOMAIN = 'localhost';
export const COOKIE_ALL_XCEED_DOMAIN = '.xceed';

export const SAME_SITE_STRICT = 'strict';
export const SAME_SITE_LAX = 'lax';

export const REFRESH_TOKEN_EXPIRATION_TIME = process.env.NEXT_PUBLIC_REFRESH_TOKEN_EXPIRATION_TIME;
