import { City } from 'models/City';

import hosts from '../hosts';
import request from '..';

export class CityController {
  static getInfo = async ({ geo, lang }) => {
    const city = await request({
      path: `/v1/cities/${geo}`,
      baseUrl: hosts.EVENT_SERVICE,
      headers: { 'Accept-Language': lang },
    });

    return new City(city);
  };

  static getAll = async ({ lang }) => {
    const cities = await request({
      path: `/v1/cities`,
      baseUrl: hosts.EVENT_SERVICE,
      headers: { 'Accept-Language': lang },
    });

    return cities.map(city => new City(city));
  };

  static getPopular = async ({ lang }) => {
    const cities = await request({
      path: `/v1/cities/popular`,
      baseUrl: hosts.EVENT_SERVICE,
      headers: { 'Accept-Language': lang },
    });

    return cities.map(city => new City(city));
  };

  static getPopularByCountry = async ({ geo, lang }) => {
    const cities = await request({
      path: `/v1/countries/${geo}/popular-cities`,
      baseUrl: hosts.EVENTS_SERVICE,
      headers: { 'Accept-Language': lang },
    });
    return cities.map(city => new City(city));
  };
}
