import React, { useCallback, useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import NotificationHub from './NotificationHub';

export const NOTIFICATION_SUCCESS_TYPE = 'success';
export const NOTIFICATION_ERROR_TYPE = 'error';
export const NOTIFICATION_INFO_TYPE = 'info';

const NotificationContext = React.createContext();
export const useNotify = () => useContext(NotificationContext);

function NotificationProvider({ children }) {
  const ref = useRef(null);

  const success = useCallback(msg => ref.current({ type: NOTIFICATION_SUCCESS_TYPE, msg }), []);
  const error = useCallback(msg => ref.current({ type: NOTIFICATION_ERROR_TYPE, msg }), []);
  const info = useCallback(msg => ref.current({ type: NOTIFICATION_INFO_TYPE, msg }), []);

  const [nofityMethods] = useState({ success, error, info });

  return (
    <NotificationContext.Provider value={nofityMethods}>
      {children}
      <NotificationHub>
        {add => {
          ref.current = add;
        }}
      </NotificationHub>
    </NotificationContext.Provider>
  );
}

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NotificationProvider;
